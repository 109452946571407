*{
    margin: 0%;
    padding: 0%;
}
h1 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  h2 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  h3 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  h4 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  h5 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-family: 500;
  }
  h6 {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  p {
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  label{
    font-family: "Poppins",sans-serif;
    font-style: normal;
  }
  button {
    justify-content: center;
    font-family: "Poppins",sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  
  ::-webkit-scrollbar {
    width: 13px;
    background: #c4c4c4;
    border-radius: 10px;
    /* margin: 0rem 1.5rem 0rem 0rem; */
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #a09f9f;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #a09f9f;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: grey;
  }
  
  @media only screen and (min-width: 501px) and (max-width: 700px) {
    h3 {
      font-size: 1rem;
    }
    h4 {
      font-size: 0.9rem;
    }
    h5 {
      font-size: 0.7rem;
    }
    p {
      font-size: 0.5rem;
    }
  }
  @media only screen and (min-width: 401px) and (max-width: 500px) {
    h1{
      font-size: 1.4rem;
    }
    h3 {
      font-size: 0.8rem;
    }
    h4 {
      font-size: 0.7rem;
    }
    h5 {
      font-size: 0.6rem;
    }
    p {
      font-size: 0.4rem;
    }
  }
  @media only screen and (min-width: 201px) and (max-width: 400px) {
    h1{
      font-size: 1.3rem;
    }
    h2{
      font-size: 0.9rem;
    }
    h3 {
      font-size: 0.7rem;
    }
    h4 {
      font-size: 0.6rem;
    }
    h5 {
      font-size: 0.5rem;
      font-family: "Poppins";
    }
    p {
      font-size: 0.4rem;
    }
  }
  