.Jobseekerdashboard-Row {
    width: 98%;
    display: flex;
    flex-direction: row;
    margin-top: 1.5rem;
    /* position: sticky; */
    top: 0;
    /* float: right; */
    right: 0;
  }
  .welcome_part{
    font-family: "Engagement",sans-serif;
    font-style: normal;
  }
  
  .Navlink_menu {
    text-decoration: none;
    color: #202020;
  }
  
  .JOBS-Section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: sticky; */
    top: 0;
    right: 0;
    gap: 1rem;
  }
  
  .Profile-Tab {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /* background-color: solid rgba(103,214,213,.7); */
    background-color: rgba(21, 185, 184, 0.6);
    /* height: 113px; */
    padding: 0.5rem;
    color: #202020;
  }
  .Profile_Tab_Left{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 2rem;
  }
  .Profile_Tab_Right{
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  
  .Welcome-Section {
    width: 36%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* gap: 0.5rem; */
    margin-left: 2rem;
    /* border:  5px solid orange; */
  }
  
  .Profile-Section {
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 4;
    cursor: pointer;
    /* border: 5px solid red; */
  }
  
  .find_my_next{
    text-align: center;
    margin: 0%;
  }
  .welcome_part{
    font-family: "Poppins",sans-serif;
  }
  .welcome{
    text-align: center;
    margin: 0%;
  }
  .Profile-Section h3 {
    margin: 2px;
    text-align: center;
  }
  
  .Profile-Section h5 {
    text-align: center;
    margin: 0;
  }
  
  .credit_notification {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 5px solid yellow; */
  }
  
  .credit_notification .credit {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .credit_heading{
    margin-top: 0.3rem;
  }
  
  .credit p {
    margin: 0;
  }
  
  .credit h3 {
    margin: 0;
  }
  
  .credit p {
    padding: 0.7rem;
    background: white;
    text-align: center;
    border-radius: 3px;
  }
  
  .credit_notification .notification {
    width: 50%;
  }
  .notification_section{
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .notification_tab {
    /* border: 3px solid blue; */
    /* width: auto;
    height: auto; */
    display: flex;
    flex-direction: column;
    margin: -1.9rem 1.7rem 0rem 0rem;
    cursor: pointer;
  }
  .notification_tab img {
    width: 70%;
    height: 70%;
  }
  .Numberof_notification {
    /* margin: -0.7rem 0rem 0rem 0rem; */
    /* margin: 0%; */
    margin: 0rem 0rem -0.7rem 0.7rem;
    z-index: 5;
    width: 20px;
    height: 20px;
    background-color: #e43e2b;
    color: #ffffff;
    font-weight: 500;
    border-radius: 50%;
    font-size: 13px;
    /* justify-content: center; */
    text-align: center;
  }
  .JOB-Card h2 {
    margin: 0%;
  }
  
  .JOB-Card h4 {
    margin: 0%;
    color: #898787;
  }
  
  .RBS {
    width: 17%;
    border: none;
    background: #ffff;
    cursor: pointer;
  }
  
  .RBH {
    width: 17%;
    border: none;
    background: #ffff;
    cursor: pointer;
  }
  
  .RB img {
    width: 80%;
  }
  
  .RBS img {
    width: 50%;
  }
  
  .RBH img {
    width: 65%;
  }
  
  .Dashboard-SideBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .DropDown-Category {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .DropDown-Category:hover {
    background-color: rgba(255, 255, 255, 0.619);
    color: black;
  }
  
  .DropDown-Category-BTN {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7711d00;
    border: none;
    color: #303939;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .DropDown-Category-BTN:hover {
    color: black;
  }
  
  .input_field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* border: 2px solid blue; */
    /* margin: -1rem 8rem 0rem 0rem; */
    height: 5rem;
  }
  
  .inputfield {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    /* border: 2px solid black; */
    align-items: flex-start;
    /* margin: 0rem 0.7rem 0rem 0rem; */
  
    /* border: 2px solid black; */
  }
  
  .inputfield input {
    width: 90%;
    padding: 0.6rem;
    border: 3px solid #f7701d;
    border-radius: 15px;
  }
  
  .Faq_FB {
    /* border: 5px solid green; */
    width: 15%;
    /* border: 5px solid green; */
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content:flex-end; */
    justify-content: center;
  
    background-color: rgba(103, 214, 213, 0.7);
    gap: 3rem;
  }
  
  .profile_img{
    width: 18%;
    height: 10%;
    margin: -36px 0px 0px 0px;
    z-index: 6;
  }
  
  .searchIcon {
    color: #f7701d;
    margin-left: -2rem;
    margin-top: 0.5rem;
    cursor: pointer;
  }
  
  .CircularProgrss {
    color: #f7701d;
    border: 3px solid #f7701d;
    background-color: #f7701d;
  }
  
  .AccountCircle {
    margin-top: -33px;
    font-size: larger;
    transform: scale(1.8);
    /* background-color: aliceblue; */
    color: aliceblue;
    cursor: pointer;
  }
  
  
  /* .clock {
    
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    height: 60px;
    width: 110px;
    color: #000000;
    border-radius: 8px;
    margin-top: .9rem;
    margin-right: .7rem;
  
  }
  
  .clock .clock_section {
    width: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: .7rem;
  }
  .clock .clock_section p {
    font-size: .8rem;
  }
  .clock .clock_section small {
    color: #000000;
    text-shadow: none;
    font-size: .6rem;
  }
  .clock span{
    margin-top: -1rem;
  }
   */
  
  @media only screen and (min-width: 1201px) and (max-width: 1300px) {
  }
  @media only screen and (min-width: 1101px) and (max-width: 1200px) {
  }
  @media only screen and (min-width: 1001px) and (max-width: 1100px) {
  }
  @media only screen and (min-width: 901px) and (max-width: 1000px) {
  }
  @media only screen and (min-width: 701px) and (max-width: 900px) {
    .Welcome-Section {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      margin-left: 1rem;
      /* border: 5px solid orange; */
    }
    .welcome{
      display: none;
    }
    .Profile-Section {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 4;
      cursor: pointer;
      /* border: 5px solid red; */
  }
    .input_field {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      /* border: 2px solid blue; */
      margin: -1rem 0rem 0rem 0rem;
      height: 5rem;
    }
    .Profile-Section {
      width: 44%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 4;
      cursor: pointer;
      /* border: 5px solid red; */
    }
    .Profile_Tab_Right {
      width: 55%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
  }
  .Profile_Tab_Left {
    width: 45%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
  }
    .Faq_FB {
      width: auto;
      display: flex;
      flex-direction: row;
      gap: 1rem;
    }
    .profile_img{
      width: 19%;
      height: 10%;
      margin: -35px 0px 0px 0px;
      z-index: 6;
    }
    .credit_notification {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 0.2rem;
      align-items: center;
      /* border: 5px solid yellow; */
    }
    .credit p {
      padding: 1.3rem;
      background: white;
      border-radius: 3px;
      text-align: center;
    }
  
    .credit_notification .credit {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media only screen and (min-width: 501px) and (max-width: 700px) {
    .Jobseekerdashboard-Row {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 0rem;
      position: sticky;
      top: 0;
      /* float: right; */
      right: 0;
    }
    .Profile-Tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(21, 185, 184, 0.6);
      height: auto;
      color: #202020;
    }
    .Welcome-Section {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      margin-left: 1rem;
      /* border: 5px solid orange; */
    }
    .Welcome-Section .welcome {
      display: none;
    }
    .input_field {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      /* border: 2px solid blue; */
      margin: -1rem 0rem 0rem 0rem;
      height: 5rem;
    }
    .Profile-Section {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 4;
      cursor: pointer;
      /* border: 5px solid red; */
    }
    .Faq_FB {
      width: auto;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-left: 1.5rem;
      align-items: center;
      justify-content: center;
      background-color: rgba(103, 214, 213, 0.7);
    }
    .credit_notification {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 1rem;
      align-items: center;
      /* border: 5px solid yellow; */
    }
    .credit p {
      padding: 0.3rem;
      background: white;
      width: max-content;
      border-radius: 3px;
      text-align: center;
    }
    .welcome_part{
      display: none;
    }
    .search_bar{
      display: none;
    }
    .clock{
      display: none;
    }
  
    .credit_notification .credit {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  @media only screen and (min-width: 401px) and (max-width: 500px) {
    .Jobseekerdashboard-Row {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 0rem;
      position: sticky;
      top: 0;
      /* float: right; */
      right: 0;
    }
    .Profile-Tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(21, 185, 184, 0.6);
      height: auto;
      color: #202020;
    }
    .Welcome-Section {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      margin-left: 1rem;
      /* border: 5px solid orange; */
    }
    .welcome_part{
      display: none;
    }
    .search_bar{
      display: none;
    }
    .clock{
      display: none;
    }
    .Welcome-Section .welcome {
      display: none;
    }
    .input_field {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      /* border: 2px solid blue; */
      justify-content: center;
      margin: -1rem 0rem 0rem 0rem;
      height: 5rem;
    }
    .Profile-Section {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      z-index: 4;
      cursor: pointer;
      /* border: 5px solid red; */
    }
    .Profile_Tab_Right {
      width: 48%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
  }
  
  
    .Faq_FB {
      width: auto;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-left: 1rem;
      align-items: center;
      justify-content: center;
      background-color: rgba(103, 214, 213, 0.7);
    }
    .credit_notification {
      width: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      gap: 1rem;
      align-items: center;
      /* border: 5px solid yellow; */
    }
    .credit p {
      padding: 0.3rem;
      background: white;
      width: max-content;
      border-radius: 3px;
      text-align: center;
    }
  
    .credit_notification .credit {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .profile_img{
      width: 32%;
      height: 10%;
      margin: -35px 0px 0px;
      z-index: 6;
    }
  }
  @media only screen and (min-width: 201px) and (max-width: 400px) {
    .Jobseekerdashboard-Row {
      width: 100%;
      display: flex;
      flex-direction: row;
      margin-top: 0rem;
      position: sticky;
      top: 0;
      /* float: right; */
      right: 0;
    }
    .Profile-Tab {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(21, 185, 184, 0.6);
      height: auto;
      color: #202020;
    }
    .Welcome-Section {
      width: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 0.5rem;
      margin-left: 1rem;
      /* border: 5px solid orange; */
    }
    .Welcome-Section .welcome {
      display: none;
    }
    .input_field {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      /* border: 2px solid blue; */
      margin: -1rem 0rem 0rem 0rem;
      height: 5rem;
    }
    .Profile-Section {
      width: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 4;
      text-align: center;
      cursor: pointer;
      /* border: 5px solid red; */
    }
    .Profile_Tab_Left {
      width: 43%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
      /* display: none; */
  }
  .welcome_part{
    display: none;
  }
  .search_bar{
    display: none;
  }
  .clock{
    display: none;
  }
    .Profile_Tab_Right {
      width: 57%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
  }
    .Faq_FB {
      width: auto;
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-left: 1.5rem;
      align-items: center;
      justify-content: center;
      background-color: rgba(103, 214, 213, 0.7);
    }
    .credit_notification {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      gap: 0.2rem;
      align-items: center;
      /* border: 5px solid yellow; */
    }
    .credit p {
      padding: 0.3rem;
      background: white;
      width: max-content;
      border-radius: 3px;
      text-align: center;
    }
  
    .credit_notification .credit {
      width: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .notification_tab img {
      width: 50%;
      height: 90%;
  }
  
  .notification_tab {
    /* border: 3px solid blue; */
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    /* justify-content: center; */
    margin: 0rem;
    cursor: pointer;
  }
  .profile_img{
    width: 39%;
    height: 10%;
    margin: -35px 0px 0px 0px;
    z-index: 6;
  }
  }
  /* @media only screen and (min-width:601px) and (max-width:700px){
  
  }
  @media only screen and (min-width:501px) and (max-width:600px){
  
  } */
  